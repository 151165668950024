import '@ghq-abi/design-system/css';
import 'core-js/actual';

import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { SessionProvider, SessionProviderProps } from 'next-auth/react';
import { AbstractIntlMessages, NextIntlProvider } from 'next-intl';
import NextNProgress from 'nextjs-progressbar';
import { DesignSystemProvider, theme } from '@ghq-abi/design-system';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FormatIcu } from '@tolgee/format-icu';
import {
  BackendFetch,
  // DevBackend,
  DevTools,
  Tolgee,
  TolgeeProvider,
  useTolgeeSSR,
} from '@tolgee/react';

import { ErrorBoundary } from '~/app/components';
import { AbilityProvider } from '~/app/contexts/AbilityContext';
import { AuthProvider } from '~/app/contexts/AuthContext';
import { FlagsProvider } from '~/app/contexts/FlagsContext';
import { FullscreenProvider } from '~/app/contexts/FullscreenContext';
import { NavProvider } from '~/app/contexts/NavContext';
import { ToastProvider } from '~/app/contexts/ToastContext';
import { globalStyles } from '~/app/styles/global';
import { AppTemplate } from '~/app/templates/App';
import { AppAbility, createUserAbility } from '~/shared/auth/permissions';
import { DEFAULT_LANGUAGE } from '~/shared/constants/i18n';
import { initializeAxeCore, queryClientConfig } from '~/shared/lib';
import { isClient, isDevelopment } from '~/shared/utils/environments';

import defaultMessages from '../app/i18n/en-US/default.json';

if (isClient() && isDevelopment()) {
  initializeAxeCore();
}

globalStyles();

type CustomAppProps = {
  Component: AppProps['Component'] & { unauth?: boolean };
  pageProps: AppProps['pageProps'] & {
    session: SessionProviderProps['session'];
    messages?: AbstractIntlMessages;
    now?: number;
    dehydratedState?: unknown;
    abilityRules?: AppAbility['_indexedRules'];
  };
};

const tolgee = Tolgee()
  // .use(DevBackend()) // Add Env validation for disable live translation in prod
  .use(BackendFetch())
  .use(DevTools())
  .use(FormatIcu())
  .init({
    defaultLanguage: 'en-US',
    defaultNs: 'default',
    apiKey: process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
    apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
  });
export default function App({
  Component,
  pageProps: {
    session,
    messages,
    now,
    abilityRules,
    language,
    isMobile,
    flags,
    userNonce,
    ...pageProps
  },
}: CustomAppProps) {
  // https://tanstack.com/query/v4/docs/react/guides/ssr#using-hydration
  const [queryClient] = React.useState(
    () => new QueryClient(queryClientConfig)
  );

  const locale = language ?? DEFAULT_LANGUAGE;

  const ssrTolgee = useTolgeeSSR(tolgee, locale);
  return (
    <ErrorBoundary>
      <TolgeeProvider tolgee={ssrTolgee}>
        <NextIntlProvider
          messages={messages ?? defaultMessages}
          now={now ? new Date(now) : undefined}
          locale={locale}
        >
          <SessionProvider session={session}>
            <QueryClientProvider client={queryClient}>
              <DesignSystemProvider>
                {Component.unauth ? (
                  <Component {...pageProps} />
                ) : (
                  <ToastProvider>
                    <AbilityProvider ability={createUserAbility(session?.user)}>
                      <FullscreenProvider
                        startState={pageProps.startFullscreenState}
                      >
                        <FlagsProvider flags={flags}>
                          <AuthProvider>
                            <NavProvider initialIsCollapsed={!!isMobile}>
                              <Hydrate state={pageProps.dehydratedState}>
                                <AppTemplate
                                  // Instead of checking `!!userNonce` it was necessary to put this other check to allow 404 pages to load
                                  hasUserNonce={userNonce !== '[EXPIRED]'}
                                >
                                  <DndProvider backend={HTML5Backend}>
                                    <Component {...pageProps} />
                                  </DndProvider>
                                </AppTemplate>
                              </Hydrate>
                              <NextNProgress
                                color={theme.colors.black.toString()}
                                startPosition={0.3}
                                stopDelayMs={200}
                                height={3}
                              />
                            </NavProvider>
                          </AuthProvider>
                        </FlagsProvider>
                      </FullscreenProvider>
                    </AbilityProvider>
                  </ToastProvider>
                )}
              </DesignSystemProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </SessionProvider>
          <Script
            type="text/javascript"
            src={process.env.NEXT_PUBLIC_PLUGIN_API_URL}
            strategy="afterInteractive"
          />
        </NextIntlProvider>
      </TolgeeProvider>
    </ErrorBoundary>
  );
}
